import axios from 'axios';


class ApiService {
  constructor(baseURL) {
    this.cacheStorage = {};

    this.axios = axios.create({
      baseURL,
    });
  }

  /*
   * Достает из кеша response по url,
   * если такой не найден, делает get запрос и добавляет в кеш
   *
   * @return Promise<response>
   */
  getCached(resource, params) {
    const value = this.cacheStorage[resource];
    if (value) return value;

    this.cacheStorage[resource] = this.get(resource, params);
    return this.cacheStorage[resource];
  }

  get(resource, params) {
    return this.axios.get(`${resource}`, { params });
  }

  post(resource, params, headers) {
    return this.axios.post(`${resource}`, params, headers);
  }

  put(resource, params) {
    return this.axios.put(`${resource}`, params);
  }

  patch(resource, params) {
    return this.axios.patch(`${resource}`, params);
  }

  delete(resource) {
    return this.axios.delete(resource);
  }

  customRequest(data) {
    return this.axios(data);
  }
}

export const apiServiceV1 = new ApiService(process.env.VUE_APP_BASE_URL_V1);
export const apiServiceV2 = new ApiService(process.env.VUE_APP_BASE_URL_V2);
export const aisRegistryService = new ApiService(process.env.VUE_APP_BASE_URL_AIS_REGISTRY);
export const dispatcherService = new ApiService(process.env.VUE_APP_BASE_URL_DISPATCHER);

export default {
  apiServiceV1,
  apiServiceV2,
  aisRegistryService,
  dispatcherService,
};
