<template lang="pug">
v-app#app

  the-navbar-guest-navigation

  v-main
    router-view(:key="$route.path")

  support-modal(:project-id="1")
  the-footer

</template>

<script>
import TheFooter from '@/components/TheFooter';
import TheNavbarGuestNavigation from '@/components/navigations/TheNavbarGuestNavigation';
import SupportModal from './modules/SupportModal/index';


export default {
  components: {
    SupportModal,
    TheFooter,
    TheNavbarGuestNavigation,
  },
};
</script>

<style lang="scss" src="@/styles/index.scss"></style>
