import { apiServiceV2, apiServiceV1 } from '@/api/api.service';


export default {

  loadKeywords({ key }) {
    return apiServiceV1.get(
      '/directory-search-keywords/list', { 'search': key },
    );
  },

  // Уровень подчиненности
  subordinationLevels() {
    return apiServiceV1.get('/directory-subordination-levels');
  },

  // Типы образовательных организаций
  providerTypes() {
    return apiServiceV1.get('/additional-provider-types');
  },

  // Департаменты
  departments() {
    return apiServiceV1.getCached('/directory-departments');
  },

  // Организационно-правовые формы
  departmentsOrganizationalForms() {
    return apiServiceV1.getCached('/directory-organizational-forms');
  },

  // Направленность программы
  programDirections() {
    return apiServiceV1.getCached('/directory-program-directions');
  },

  // Уровни образования педагогов
  teacherLevels() {
    return apiServiceV1.get('/teacher-levels');
  },

  // Профессиональные категории педагогов
  teacherCategories() {
    return apiServiceV1.get('/teacher-categories');
  },

  // Уровни соответствия квалификации
  teacherSkillLevels() {
    return apiServiceV1.get('/teacher-skill-levels');
  },

  // Достижения преподавателей
  teacherAwards() {
    return apiServiceV1.get('/teacher-awards');
  },

  // Справочник документов программ
  progDocumentTypes() {
    return apiServiceV1.get('/directory-program-documents');
  },

  // Адреса для выбора основного адреса реализации программы
  progAddresses() {
    return apiServiceV2.get('/addresses');
  },

  // Виды деятельности для программ (автокомплит)
  progActivitiesFiltered(name) {
    return apiServiceV1.get(
      `/directory-program-activities?search[name]=${name}`,
    );
  },

  // Список интересов для программ (автокомплит)
  programInterests(name) {
    return apiServiceV1.get(
      `/directory-interests?search[name]=${name}`,
    );
  },

  // Виды деятельности для программ (по id)
  programActivity(id) {
    return apiServiceV1.get(
      `/directory-program-activities/${id}`,
    );
  },

  // Средства обучения (автокомплит)
  learningToolsFiltered(name) {
    return apiServiceV1.get(
      `/directory-learning-tools?search[name]=${name}`,
      { status: 1 },
    );
  },

  // Средство обучения по ID
  learningToolById(id) {
    return apiServiceV1.get(
      `/directory-learning-tools/${id}?expand=unity,categories`,
    );
  },

  // Средство обучения по ID API V2
  learningToolByIdV2(id) {
    return apiServiceV2.get(
      `/programs/learning-tools/${id}?expand=status,unity`,
    );
  },

  // Ключевые слова (автокомплит)
  keywordsFiltered(name) {
    return apiServiceV1.get(
      `/directory-search-keywords/list?search=${name}`,
    );
  },

  // Ключевые слова (автокомплит)
  keywordsFaqFiltered(name) {
    return apiServiceV1.get(
      '/faq-keywords',
      { 'search[name]': name },
    );
  },

  // Муниципалитеты
  municipalities(params) {
    return apiServiceV2.getCached('/municipalities', params);
  },

  // Муниципалитеты (автокомплит)
  municipalitiesFiltered({ name }) {
    return apiServiceV2.get(
      `/municipalities?search[name]=${name}&search[own_operator_only]=1`,
    );
  },
  // Муниципалитеты публичные (автокомплит)
  publicMunicipalitiesFiltered({ name }) {
    return apiServiceV2.get(
      `/public/municipalities?search[name]=${name}`,
    );
  },

  // Муниципалитеты по ОКТМО
  municipalitiesByOKTMO(oktmo) {
    return apiServiceV2.get(`/addresses/municipality?oktmo=${oktmo}`);
  },

  // Муниципалитеты по ID
  municipalityByID(id) {
    return apiServiceV2.get(`/municipalities/${id}`);
  },

  // Заболевания
  illnesses() {
    return apiServiceV1.getCached('/illnesses');
  },

  organizationTypes() {
    return apiServiceV2.getCached('directory/organization-types');
  },

  // информация о платных модулях
  getModulesInfo(params) {
    return apiServiceV1.get('/directory-pay-modules?expand=content_operators', params);
  },

};
