<template lang="pug">
v-dialog(v-model="show" max-width="700")
  .dialog
    v-toolbar(color="primary")
      v-toolbar-items
        v-icon(@click="show=false") close

      v-toolbar-title Получить сертификат

    v-card.contract-request(flat)
      v-card-text
        .ma-2
          p Вы можете обратиться в учреждения для получения сертификата в Вашем муниципалитете
          autocomplete-munitipality(v-model="munitipality")

      v-card-text.actions
        slot(name="actions")
          v-layout
            v-spacer
            v-btn(@click="show=false" depressed) Закрыть

</template>

<script>
import AutocompleteMunitipality from '@/components/inputs/AutocompleteMunitipality';


const SOURCE_TYPES = {
  notChosen: 0,
  pfdo: 1,
  outer: 2,
  reestr: 3,
};

export default {
  name: 'GetCertificateDialog',
  components: {
    AutocompleteMunitipality,
  },
  props: {
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      items: [],
      munitipality: null,
      SOURCE_TYPES,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    show(val) {
      if (val) this.init();
    },
    munitipality(val) {
      if (val) this.$router.push({ name: 'munitipality-info', params: { id: val } });
    },
  },
  methods: {
    init() {

    },
  },
};
</script>
