const SetErrorsFromResponse = {};


SetErrorsFromResponse.install = (Vue) => {
  function setErrorsFromResponseValidation(errors) {
    if (!Object.prototype.hasOwnProperty.call(this, '$validator')) {
      return;
    }
    // clear errors
    this.$validator.errors.clear();

    Object.entries(errors).forEach(([key, value], i) => {
      this.$toasted.error(value, { duration: 3000 + 500 * i, icon: 'error' });
      this.$validator.errors.add({
        field: key,
        msg: value,
      });
    });
  }

  function setErrorsFromResponse(response) {
    if (response.data.result_code === 'VAL01') {
      this.$setErrorsFromResponseValidation(response.data.errors.validation);
    } else {
      let alertMsg;
      if (Object.prototype.hasOwnProperty.call(response.data, 'data')) {
        alertMsg = response.data.data.message || response.data.result_message;
      } else {
        alertMsg = response.data.result_message;
      }
      this.$toasted.error(alertMsg, { duration: 3000 });
    }
    return response;
  }

  Vue.prototype.$setErrorsFromResponse = setErrorsFromResponse;
  Vue.prototype.$setErrorsFromResponseValidation = setErrorsFromResponseValidation;
};


export default SetErrorsFromResponse;
