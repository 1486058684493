import { apiServiceV2 } from '@/api/api.service';


export default {

  getMunitipalityInfo(munitipalityId) {
    return apiServiceV2.get(`/main-page/info-mun/${munitipalityId}`);
  },

  getRegions() {
    return apiServiceV2.getCached('/main-page/regions');
  },

  getQuestions(idsList) {
    const params = { 'search[keywords_ids]': idsList };
    return apiServiceV2.get('/public/faq/search', params);
  },

  getAnswer(id) {
    return apiServiceV2.getCached(`/public/faq/${id}`);
  },

  // getInstructions() {
  //   if (store.getters.isAuthenticated) {
  //     return apiServiceV2.get('/public/instructions');
  //   }
  //   return apiServiceV2.get(`/public/instructions?operator=${getOperatorId()}`);
  // },

  // getInstructionDetail(id) {
  //   if (store.getters.isAuthenticated) {
  //     return apiServiceV2.get(`/public/instructions/${id}`);
  //   }
  //   return apiServiceV2.get(`/public/instructions/${id}?operator=${getOperatorId()}`);
  // },

  // getInstructionPdf(id) {
  //   if (store.getters.isAuthenticated) {
  //     return apiServiceV2.get(`public/instructions/download-pdf/${id}`);
  //   }
  //   return apiServiceV2.get(`/public/instructions/download-pdf/${id}?operator=${getOperatorId()}`);
  // },

  // getInstructionsPdf() {
  //   if (store.getters.isAuthenticated) {
  //     return apiServiceV2.get('/public/instructions/download-manuals');
  //   }
  //   return apiServiceV2.get(`/public/instructions/download-manuals?operator=${getOperatorId()}`);
  // },

  getVideoLessons() {
    return apiServiceV2.get('/public/instructions/video-instructions');
  },

  getCertificateWays(id) {
    return apiServiceV2.get(`/main-page/certificate-ways/${id}`);
  },

  getRegionsForMap(params) {
    return apiServiceV2.get('/operator/regions', params);
  },

};
