<template lang="pug">
footer
  v-layout.contacts
    v-container(grid-list-md)
      .contacts__socials
        a(href="https://www.instagram.com/pfdo_ru")
          instagram.socials--icon
        a(href="https://vk.com/publicpfdo")
          vk.socials--icon
        router-link.policy(:to="{ name: 'information-policy' }" target="_blank") Политика обработки персональных данных
        a.contacts__socials-download(href="https://play.google.com/store/apps/details?id=ru.pfdo.app.certificate")
          span Скачать «ОНФ. Сертификат»
          v-img(
            :src="require('@/assets/icons/google-play.png')"
            max-width="100"
            contain
          )


</template>

<script>
import Vk from '@/assets/icons/vk';
import Instagram from '@/assets/icons/instagram';

export default {
  components: {
    Vk,
    Instagram,
  },
};
</script>

<style lang="scss" scoped>

  .contacts__socials-download {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $dark-color;
  }

  a {
    display: inline-block;
  }

  .policy {
    display: block;
    width: max-content;
    margin: 0 auto;
  }

</style>
