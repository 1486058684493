/*
  Набор валидаторов для vuetify

  Использование:
  this.$rules
*/
const CERT_LENGTH = 10;


const RULES = {
  max(minVal) {
    return (v) => (v < minVal || `Максимально допустимое значение ${minVal}`);
  },
  min(minVal) {
    return (v) => (v > minVal || `Минимально допустимое значение ${minVal}`);
  },
  emailConfirm(email) {
    return (v) => v === email || 'Email должен совпадать';
  },
  maxSymbols(count) {
    return (v) => ((v || '').length <= count || `Поле должно содержать не более ${count} символов`);
  },
  containSymbols(count) {
    return (v) => ((v || '').length === count || `Поле должно содержать ${count} символов`);
  },
  minSymbols(count) {
    return (v) => ((v || '').length >= count || `Поле должно содержать не менее ${count} символов`);
  },
  required: (value) => !!value || 'Поле обязательно для заполнения',
  email: (v) => /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/.test(v) || 'Email введен неверно',
  url: (v) => /^(ftp|http|https):\/\/[^ "]+$/.test(v) || 'URL адрес введен неверно',
  certificate: (v) => (v && v.length === CERT_LENGTH && !/[^0-9]/.test(v)) || 'Номер сертификата должен состоять из 10 цифр',
  passportSeries: (v) => v.length === 4 || 'Поле должно содержать 4 символа',
  passportNumber: (v) => v.length === 6 || 'Поле должно содержать 6 символов',
  emailRepeat: (v) => /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/.test(v),
};

export default {}.install = (Vue) => {
  Vue.prototype.$rules = RULES;
};
