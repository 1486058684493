const routes =  [
  {
    path: '/modules',
    name: 'modules',
    component: () => import('./views/PaidModules'),
    meta: {
      title: 'Платные модули',
    },
  },
  {
    path: '/modules/:module',
    name: 'module-page',
    component: () => import('./views/PaidModulePage'),
    meta: {
      title: 'Платные модули',
    },
  },
];

export default routes;
