<template lang="pug">
v-dialog.support-dialog(
  v-model="dialog"
  max-width="800px"
  height="90vh"
  min-height="800px"
  scrollable
)

  template(v-slot:activator="{ on, attrs }")
    div.support-dialog__open-btn(@click="dialog = true" v-bind="attrs")
      v-btn.rounded(color="secondary" fab text)
        v-img(:src="require('@/assets/icons/saveliver.svg')")


  v-card.scroll-y(height="100%")
    v-overlay(absolute :value="loading")
      v-progress-circular(indeterminate)

    .d-flex.mb-4.pa-4
      v-avatar.subheading.white--text.mr-2(color="primary" size="24") {{ step + 1 }}
      span.font-weight-regular Отправить сообщение в техподдержку
      v-btn.ml-auto.rounded(color="error" @click="dialog = false" fab tile x-small)
        v-icon close

    v-window(v-model='step')
      v-window-item
        v-form.pa-4(ref="form1")
          v-text-field(
            v-model="form.email"
            required
            type="email"
            :rules="[$rules.required, $rules.email]"
            label="Email"
          )
          v-expand-transition
            div(v-if="$rules.emailRepeat(form.email)")
              span Введите повторно email адрес
              v-text-field(
                v-model="form.email_confirm"
                required
                type="email"
                :rules="[$rules.required, $rules.email, $rules.emailConfirm(form.email)]"
                label="Подтвердите email"
                @paste="pasteClickHandler"
              )
          directory-region-input(
            v-model="form.region_id"
            label="Мой регион"
            placeholder=""
          )
          directory-mun-input(
            v-model="form.municipality_id"
            label="Мой муниципалитет"
            :disabled="!form.region_id"
            :rules="[$rules.required,]"
            :region="form.region_id"
          )
        .pa-4.d-flex
          v-spacer
          v-btn.mr-2(@click="dialog  = false" text) Скрыть
          v-btn(color="primary" @click="nextStep(1)") Дальше

      v-window-item
        v-form.pa-4(ref="form2")
          select-question-category(
            v-model="form.question_category_id"
            label="Выберите категорию обращения"
            :project-id="projectId"
            :rules="[$rules.required]"
          )
          v-textarea(
            v-model.lazy="form.full_text"
            label="Текст обращения"
            rows="6"
            auto-grow
            :rules="[$rules.required, $rules.minSymbols(3)]"
          )

          input(
            type="file",
            style="display: none",
            ref="files",
            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            multiple,
            @change="onDocumentsPicked"
          )
          p.caption.text--secondary.mb-1 Прикрепить файлы (максимальный размер 5 мб)
          v-alert.pointer.mb-0(
            type="info"
            @click="pickDocuments"
            icon="attachment"
          ) Выбрать файл (pdf, docx, xmls)
            v-overlay(absolute :value="loadingFile")
              v-progress-circular(indeterminate)
          v-list-item.pa-0(v-for="(document, idx) in form.documents" outlined :key="idx")
            v-list-item-action.mr-1
              a(target="_blank" :href="document.url")
                v-btn(color="primary" fab small icon)
                  v-icon(small) remove_red_eye
            v-list-item-content
              v-list-item-title {{ document.file_name }}
            v-list-item-action
              v-btn.elevation-0(@click="form.documents.splice(idx, 1)" fab small)
                v-icon(color="error") close

        v-card.mx-4.mb-5(
          height="100%"
          @drop.stop.prevent="handleFileDrop"
          @dragover.stop.prevent
          @dragmove.stop.prevent
        )
          template(v-if="images.length")
            v-img.relative(
              contain
              height="100%"
              width="100%"
              max-height="100%"
              :src="images[selectedImage]"
            )
              .screenshoot-actions
                v-tooltip(bottom)
                  template(#activator="{ on, attrs }")
                    v-btn.ma-2(
                      @click="images.splice(selectedImage, 1); selectedImage = 0"
                      v-on="on"
                      :attrs="attrs"
                      color="error"
                      fab
                      small
                    )
                        v-icon close
                  span Удалить изображение
              .support-dialog__images__preview.pa-5(v-if="images.length > 1")
                v-img.mx-2.rounded(
                  v-for="(image, index) in images"
                  @click="selectedImage = index"
                  :class="{ 'support-dialog__images__preview-image--active': selectedImage === index }"
                  min-width="80px"
                  min-height="80px"
                  max-width="80px"
                  max-height="80px"
                  :src="image"
                  :key="index"
                )
          .pa-4.d-flex
            v-icon.align-self-start.mr-2.mt-1 image
            p.blue-grey--text.darken-2.mb-0.subtitle-1
              | Сделайте скриншот с помощью кнопки &nbsp;
              span.font-weight-bold.grey.rounded.px-1.white--text PrtSc
              | &nbsp; или  &nbsp;
              span.font-weight-bold.grey.rounded.px-1.white--text PrintScreen
              | &nbsp; и нажмите &nbsp;
              span.font-weight-bold.grey.rounded.px-1.white--text Ctrl + V
              | &nbsp; для его вставки
              | или перенесите сюда изображение
        .pa-4.d-flex
          v-btn(text @click="step = 0") Назад
          v-spacer
          v-btn.mr-2(@click="dialog  = false" text) Скрыть
          v-btn(color="success" @click="getHints(form.full_text)") Отправить
      v-window-item.px-4
        strong.ml-4 Возможно, вы имели ввиду:
        v-expansion-panels.py-4
          v-expansion-panel(v-for="(i, idx) in hints" :key="idx")
            v-expansion-panel-header.text-body-1: strong {{i.title}}
            v-expansion-panel-content.white
              div(v-html="i.text")
        div.py-4
          v-btn(color="success" block @click="createApplication") в списке нет того, что меня интересует - отправить обращение

        .py-4.d-flex
          v-btn(text @click="step = 1") Назад
          v-spacer
          v-btn.mr-2(@click="dialog  = false" text) Скрыть


</template>
<script>
import api from './api.js';
import { dataURItoBlob, fileToBlob, pasteImage } from './helpers';

import DirectoryRegionInput from './components/RegionInput.vue';
import DirectoryMunInput from './components/MunicipalityInput.vue';
import SelectQuestionCategory from './components/SelectQuestionCategory.vue';

const CHUNK_SIZE = 250 * 1024;

const DEFAULT_FORM = {
  question_category_id: null,
  municipality_id: null,
  region_id: null,
  email: '',
  email_confirm: '',
  text: '',
  full_text: '',
  images: [],
  documents: [],
};

/**
 * копия данного функционала находится и в main-spa
 */
export default {
  name: 'SupportModal',
  components: {
    DirectoryRegionInput,
    DirectoryMunInput,
    SelectQuestionCategory,
  },
  filters: {
    extension(file) {
      const { length } = file.split('.');
      return file.split('.')[length - 1].toUpperCase();
    },
  },
  props: {
    projectId: { type: Number, required: true },
    email: { type: String, required: false, default: '' },
  },
  data() {
    return {
      hints: [],
      debouncedHints: null,
      dialog: false,
      images: [],
      selectedImage: 0,

      error: null,

      step: 0,

      loadingFile: false,
      loading: false,

      form: { ...DEFAULT_FORM },
    };
  },

  watch: {
    dialog(v) {
      if (v) {
        if (this.email) {
          this.form.email = this.email;
          this.form.email_confirm = this.email;
        }
      }
      this.$emit('input', v);
    },
    region_id() {
      this.form.municipality_id = null;
    },
  },

  created() {
    this.$root.$on('openSupportModal', this.openModal);
  },

  mounted() {
    window.addEventListener('paste', this.pasteImage);
  },

  beforeDestroy() {
    window.removeEventListener('paste', this.pasteImage);
  },

  methods: {
    pasteClickHandler(e) {
      e.preventDefault();
      return false;
    },
    openModal(fromError) {
      if (fromError) {
        // { id: 100, name: "Иной технический вопрос", project_role_id: 18 }
        this.form.question_category_id = 100;
      }
      this.dialog = true;
    },

    nextStep(step) {
      const form = this.$refs[`form${step}`];
      if (form && form.validate()) {
        this.step = step;
      } else if (!form) {
        this.step = step;
      }
    },

    addImage(image) {
      // Запретить загрузку если шаг не активен и не открыто обращение
      if (this.step !== 0 && !this.dialog) return;

      if (this.images.length >= 5) {
        this.$toasted.error('Вы можете загрузить не больше 5 картинок');
      } else {
        this.images.unshift(image);
      }
    },

    pickDocuments() {
      this.$refs.files.click();
    },
    async getHints(value) {
      const params = {
        text: value,
        project_id: this.projectId,
      };
      this.$rHandler(
        api.getHints(params),
        ({ data }) => {
          if (data.data.length > 0) {
            this.nextStep(2);
            this.hints = data.data;
          } else this.createApplication();
        },
        () => {
          this.createApplication();
        },
      );
    },

    async createApplication() {
      if (!this.$refs.form2.validate()) return false;
      this.loading = true;
      if (this.form.full_text) {
        this.form.text = this.form.full_text.split(' ').splice(0, 2).join(' ');
      }
      try {
        const data = {
          ...this.form,
          project_id: this.projectId,
          page_url: window.location.href,
          screen_resolution: {
            width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
            height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
          },
        };
        delete data.email_confirm;

        if (data.municipality_id) delete data.region_id;
        if (!data.municipality_id) delete data.municipality_id;

        if (this.images.length) {
          const responses = await Promise.all(this.images.map((image) => this.uploadFile(dataURItoBlob(image))));
          data.images = responses.map((response) => response.data.data.file_name);
        }


        if (!data.documents?.length) delete data.documents;
        else data.documents = data.documents.map((item) => (item.file_name));

        await api.createQuestion(data);

        this.form = { ...DEFAULT_FORM };
        this.form.documents = [];
        this.images = [];
        this.step = 0;
        this.region_id = null;
        this.dialog = false;

        this.$toasted.success('Обращение успешно отправлено');
      } catch ({ response }) {
        if (response?.data?.result_code === 'VAL01') {
          Object.values(response.data.validation).forEach((err) => {
            this.$toasted.error(err, { icon: 'error', duration: 5000 });
          });
        } else {
          this.$toasted.error('Не удалось создать обращение, попробуйте позже');
        }
      } finally {
        this.loading = false;
      }
      return true;
    },

    async pasteImage(event) {
      const image = await pasteImage(event);
      this.addImage(image);
    },

    handleFileDrop(event) {
      const { dataTransfer: { files } } = event;
      if (files) {
        const types = ['image/jpeg', 'image/png', 'image/jpg'];

        [...files].forEach((file) => {
          if (!types.includes(file.type)) {
            this.$toasted.error('Файл должен быть картинкой');
          } else {
            fileToBlob(file).then((image) => {
              this.addImage(image);
            });
          }
        });
      }
    },

    async onDocumentsPicked(e) {
      const { files } = e.target;
      await [...files].forEach(
        async (document) => {
          try {
            this.loadingFile = true;
            const response = await this.uploadFile(document);
            this.form.documents.push({ ...response.data.data });
          } catch (error) {
            this.$toasted.error('Невозможно загрузить файл');
          } finally {
            this.loadingFile = false;
          }
        },
      );
    },

    async uploadFile(file, fn = function () { }) {
      const img = new Image();
      img.src = file.name;
      const fileSize = file.size;
      const dispositionName = Math.random().toString(32);

      if (fileSize > 1024 * 1024 * 5) {
        throw new Error('Размер файла слишком большой');
      }

      let response;

      for (let start = 0; start < fileSize; start += CHUNK_SIZE) {
        const data = new FormData();
        data.append('file', file.slice(start, start + CHUNK_SIZE), dispositionName);
        // eslint-disable-next-line no-await-in-loop
        response = await api.uploadFile({
          data, start, fileSize, dispositionName,
        });

        fn(Math.max((start / fileSize) * 100, 1));
      }
      return response;
    },
  },
};
</script>
<style lang="scss">
$text-color: rgb(27, 129, 230);

.screenshoot-actions {
  position: absolute;
  left: 5px;
  top: 5px;
}

.support-dialog {
  &__open-btn {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    &:before {
      content: "";
      pointer-events: none;
      opacity: 0.9;
      background: radial-gradient(
          circle at 20% 35%,
          transparent 0,
          transparent 2px,
          $text-color 3px,
          $text-color 4px,
          transparent 4px
        ),
        radial-gradient(
          circle at 75% 44%,
          transparent 0,
          transparent 2px,
          $text-color 3px,
          $text-color 4px,
          transparent 4px
        ),
        radial-gradient(
          circle at 46% 52%,
          transparent 0,
          transparent 4px,
          $text-color 5px,
          $text-color 6px,
          transparent 6px
        ),
        radial-gradient(
          circle at 10% 42%,
          transparent 0,
          transparent 4px,
          $text-color 5px,
          $text-color 6px,
          transparent 6px
        );
      width: 100%;
      height: 300%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 7;
      animation: bubbles 3s linear infinite both;
    }
    svg {
      animation: scale 3s infinite;
    }
  }
  &__images__preview {
    display: flex;
    justify-content: center;
    position: absolute;
    background: #00000090;
    bottom: 0px;
    width: 100%;

    &-image--active {
      transform: scale(1.2);
    }
  }

}
.v-input--is-disabled {
  .v-input__append-inner {
    display: none;
  }
}

@keyframes bubbles {
  0% {
    transform: translate(1);
  }
  100% {
    transform: translate(0, -50%) scale(0);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

</style>
