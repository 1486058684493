<template lang="pug">
  v-container.py-0(fluid)
    v-container.pa-0(grid-list-md)
      .banner__img
        v-img(
          :src="require('@/assets/images/enrollments-banner.jpg')"
          cover
          height="100%"
          position="center"
        )
      .banner__text
        h2.white--text Уверены, что данные Вашего ребенка не используют для фиктивных зачислений?
          br
          | Проверьте!
        v-btn.mt-3(depressed :to="{ name: 'check-enrollments' }") Проверить мои зачисления

</template>

<script>
export default {
  name: 'CheckEnrollmentsBanner',
};
</script>

<style scoped lang="scss">

  .container--fluid {
    background-color: #6980A2;
    height: 400px;
    position: relative;
    overflow: hidden;

    .container {
      display: flex;
      height: 100%;
      position: relative;
    }

    .banner__img {
      transform: scale(-1, 1);
      background-color: $primary-color;
      position: absolute;
      flex-shrink: 0;
      width: calc((100vw - 100% - 17px) / 2 + 100%);
      height: 100%;
      left: 40%;
      top: 0;
      opacity: .6;
      /*clip-path: polygon(45% 0, 100% 0%, 100% 100%, 35% 100%);*/

      .v-image {
        position: relative;
        z-index: 1;
      }
    }

    .banner__text {
      width: 35%;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      padding-top: 30px;
      justify-content: center;

      h2 {
        font-weight: normal;
      }

      .v-btn {
        background-color: #5c4586;
        color: white;
      }
    }
  }

  @media (max-width: 768px) {
    .container--fluid {
      height: 300px;

      .container {
        position: unset;
      }

      .banner__img {
        width: 100%;
        left: 0;
        clip-path: unset;

        .v-image {
          opacity: .3;
        }
      }

      .banner__text {
        z-index: 1;
        text-align: center;
        width: 100%;
      }
    }
  }

</style>
