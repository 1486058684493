import { apiServiceV2 } from '@/api/api.service';


export default {

  getNew(id, params) {
    return apiServiceV2.get(`/main-page/news/${id}`, params);
  },

  getNews(page, params) {
    return apiServiceV2.get('/main-page/news', {
      'page': page,
      ...params,
    });
  },

  getHotNews(params) {
    return apiServiceV2.get('/main-page/news', params);
  },

  getManagementNews(params) {
    return apiServiceV2.get('/news', params);
  },

  getManagementNew(id, params) {
    return apiServiceV2.get(`/news/${id}`, params);
  },

  removeManagementNew(id) {
    return apiServiceV2.delete(`/news/${id}`);
  },

  createManagementNew(payloads) {
    return apiServiceV2.post('/news', payloads);
  },

  updateManagementNew(id, payloads) {
    return apiServiceV2.patch(`/news/${id}`, payloads);
  },

};
