<template lang="pug">
v-container(grid-list-md)

  v-layout(row wrap)
    v-flex.certify(md4 sm12)
      certificate-info-card
    v-flex.certify(md4 sm12)
      certificate-get-card
    v-flex.certify(md4 sm12)
      certificate-use-card

</template>

<script>
import CertificateInfoCard from './CertificateInfoCard';
import CertificateGetCard from './CertificateGetCard';
import CertificateUseCard from './CertificateUseCard';

export default {
  name: 'CertificateFaq',
  components: {
    CertificateInfoCard,
    CertificateGetCard,
    CertificateUseCard,
  },
};
</script>

<style lang="scss">
.certify {
  display: flex;

  &__container {
    height: 100%;
    display: flex;
    min-height: 500px;
    flex-direction: column;

    h2 {
      height: 80px;
      text-align: center;
      color: $primary-color;
      margin-bottom: 10px;
      font-size: 25px;
      text-transform: uppercase;

      @media (max-width: 1264px) {
        font-size: 20px;
      }
    }
    .certify-info {
      flex: 1 1 auto;
      min-height: 0px;
      overflow-y: auto;

      &__content {
        max-height: 0;
      }
    }
    .image {
      flex: 0 0 auto;
    }
    .actions {
      text-align: center;
    }

  }

  &__container.more-opened {
    h1 {
      margin-bottom: 0px;
    }
    .image {
      max-height: 0px;
    }
    .certify-info {
      &__content {
        max-height: 500px;
      }
    }
  }
}
</style>
