const RHandler = {};

const ERROR_DELAY = 5000;
const ERROR_TEXT = 'Произошла непредвиденная ошибка, сообщите об ошибке администратору.';

/*
 * rHandler(responseHandler)
 *
 * Обработчик ошибок с бекенда PFDO,
 * обрабатывает промисы, принимая обязательную функцию,
 * которая возвращает промис, а также
 * принимает 2 необязательных функции:
 * 1. onSuccess - функция, которая будет вызвана в случае успешного запроса
 * 2. onReject - функция, которая будет вызвана в случае неудачного запроса
 *
 * rHandler выводит ошибку из result_message по умолчанию в слуаче неудачного запроса,
 * Чтобы не выводить result_message - функция onReject должна возвращать любое значение, кроме undefined
 * во всех остальных случаях по умолчанию result_message выводится
 */
RHandler.install = (Vue) => {
  const rHandler = (promise, onSuccess = () => {}, onReject = () => {}) => (
    promise
      .then((response) => {
        if (response.data.result_code !== 'FLSCS') {
          const rejectResponse = onReject(response);
          if (rejectResponse === undefined) {
            Vue.toasted.error(
              response.data.data?.message || response.data.result_message,
              { duration: ERROR_DELAY, icon: 'error' },
            );
          }
          return rejectResponse;
        }
        return onSuccess(response);
      })
      .catch((error) => {
        let errorMsg  = `[${error.message}] ${ERROR_TEXT}`;
        if (error.response && error.response.data && error.response.data.result_message) {
          errorMsg = error.response.data.result_message;
        }
        Vue.toasted.error(errorMsg, {
          duration: ERROR_DELAY,
          icon: 'error',
        });
      })
  );

  Vue.rHandler = rHandler;
  Vue.prototype.$rHandler = rHandler;
};

export default RHandler;
