const routes =  [
  {
    path: '/information/policy',
    name: 'information-policy',
    component: () => import('./views/InformationPolicy.vue'),
    meta: {
      title: 'Правила обработки персональных данных',
    },
  },
];

export default routes;
