import ru from 'vuetify/es5/locale/ru';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);
const vuetifyOptions = {
  lang: {
    locales: { ru },
    current: 'ru',
  },
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#3e66a7',
        secondary: '#fb8c00',
        light: '#F8F8F8',
        lesslight: '#E9EBF0',
        dark: '#4C505F',
      },
      dark: {
        primary: '#9a6afc',
        secondary: '#fb8c00',
        dark: '#F8F8F8',
        lesslight: '#494949',
        light: '#303030',
      },
    },
  },
  customProperties: true,
};

export default new Vuetify(vuetifyOptions);
