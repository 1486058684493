import Home from './index';

const routes =  [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  { path: '/app', redirect: '/' },
  {
    path: '/public/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/News.vue'),
  },
  {
    path: '/public/news/:page',
    name: 'news-paginated',
    component: () => import('./views/News.vue'),
  },
  {
    path: '/public/new/:id',
    name: 'new',
    component: () => import('./views/New.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('./views/faq/Faq.vue'),
  },
  {
    path: '/faq/checklist',
    name: 'faq-checklist',
    component: () => import('./views/faq/FaqChecklist.vue'),
  },
  {
    path: '/faq/instructions',
    name: 'faq-instructions',
    component: () => import('./views/faq/Instructions.vue'),
  },
  {
    path: '/faq/instruction/:id',
    name: 'faq-instruction-detail',
    component: () => import('./views/faq/InstructionDetail.vue'),
  },
  {
    path: '/faq/lessons',
    name: 'faq-lessons',
    component: () => import('./views/faq/Lessons.vue'),
  },
  {
    path: '/public/munitipality-info/:id',
    name: 'munitipality-info',
    component: () => import('./views/InfoMunitipality.vue'),
  },
  {
    path: '/public/select-region',
    name: 'select-region',
    component: () => import('./views/SvgMap.vue'),
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('./views/SignIn.vue'),
  },
  {
    path: '/create-certificate',
    name: 'create-certificate',
    component: () => import('./views/CreateCertificate/index.vue'),
  },
];

export default routes;
