const routes =  [
  {
    path: '/enrollments',
    name: 'check-enrollments',
    component: () => import('./index'),
    meta: {
      title: 'У меня есть зачисления?',
    },
  },
  {
    path: '/confirm-email',
    name: 'enrollments-confirm-email',
    component: () => import('./views/ConfirmEmail'),
    meta: {
      title: 'Подтверждение почты',
    },
  },
];

export default routes;
