<template lang="pug">
v-card(flat)
  v-card-text.certify__container(
    :class="{ 'more-opened': showMoreInfo }"
  )
    v-img.image(:src="require('@/assets/images/certificateFaq/index003.png')")
    .certify-info
      h2 Как использовать сертификат?
      the-loading(v-if="loading")
      .certify-info__content(v-if="showMoreInfo" v-html="content")
      p(v-else) Сертификат предоставляет Вам доступ к персональному счету, средства с которого направляются
        |  на оплату заключаемых договоров об обучении. Направьте электронную заявку через свой личный
        |  кабинет или назовите номер сертификата непосредственно в организации,
        |  и с Вами заключат договор, по которому «платить» будет сертификат.
</template>

<script>
import MainService from '@/services/main';


export default {
  data() {
    return {
      showMoreInfo: false,
      loading: false,
      content: 'Дополнительная информация не найдена',
    };
  },
  methods: {
    getContentCard() {
      this.loading = true;
      this.$rHandler(
        MainService.getUseCertificateCard(),
        (response) => {
          this.content = response.data.data.text;
          this.loading = false;
        },
      );
      this.showMoreInfo = !this.showMoreInfo;
    },
  },
};
</script>
