export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  // eslint-disable-next-line max-len
  if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]); } else { byteString = unescape(dataURI.split(',')[1]); }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const fileToBlob = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = function () {
    resolve(reader.result);
  };
  reader.onerror = function (err) {
    reject(err);
  };
  reader.readAsDataURL(file);
});

// eslint-disable-next-line consistent-return
export const pasteImage = (event) => new Promise((resolve, reject) => {
  if (!event && !event.clipboardData) {
    reject(new Error('На данном устройсве невозможна вставка'));
  }

  // получаем все содержимое буфера
  const { items } = event.clipboardData;
  if (items) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        // представляем изображение в виде файла
        return fileToBlob(items[i].getAsFile())
          .then((file) => {
            resolve(file);
          })
          .catch((err) => {
            reject(err);
          });
      }
    }
  }
});

export const getRoleName = () => 'guest';

export default {};

