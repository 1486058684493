<template lang="pug">
nav
  v-layout.bottom-nav
    v-container(grid-list-md).py-3
      v-layout(row wrap)
        .logo
          div.ratio_4-1
            router-link.ratio_4-1__inner(to="/")
              v-img(
                @click="goTop"
                :src="require('@/assets/images/logo.png')"
                position="left center"
                :transition="false"
                contain
              )
        v-flex.menu-links(align-self-center :class="{ active: showMenu }" @click="toggleMenu")
          v-btn.ml-2(depressed to="/").menu-links__link Главная
          v-btn.ml-2(depressed to="/public/news").menu-links__link Новости
          v-btn.ml-2(depressed to="/public/select-region" color="primary" outlined) Получить сертификат

        v-flex.menu-hamburger
          v-btn(text icon color="primary" @click="toggleMenu")
            v-icon(v-if="showMenu") close
            v-icon(v-else) menu
</template>

<script>
import goTo from 'vuetify/lib/services/goto';

export default {
  data: () => ({
    showMenu: false,
  }),
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    goTop() {
      return goTo(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 900;
}

nav {
  position: sticky;
  top: 0;
  z-index: 4;

  .menu-hamburger {
    display: none;
  }
  .bottom-nav {

    background: $lesslight-color;

    .menu-links,
    .menu-hamburger {
      text-align: right;

      &__link {
        color: $primary-color !important;
        background-color: $lesslight-color !important;
        border-color: $lesslight-color !important;
        font-weight: normal;
        border-radius: 3px;

        &:after {
          content: none;
        }
      }
      .question-btn {
        font-size: 18px;
        font-weight: bolder;
        font-family: Rubik, sans-serif;
        box-shadow: 0 0 0 rgba(62, 102, 167, 0.5);
        animation: pulse 2s infinite;
      }
    }
  }
}

.logo {
  max-width: 190px;
  width: 100%;
}

.ratio_4-1 {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 25%;

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .v-image {
      height: 100%;
      width: 100%;
    }
  }
}

</style>
