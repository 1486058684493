<template lang="pug">
v-container.the-news(grid-list-md)
  h2.the-news__title Актуальные Новости
  p.the-news__subtitle
    router-link.primary--text(to="/public/news") Перейти ко всем новостям
  div(
    @mouseover="carouselCycle = false"
    @mouseleave="carouselCycle = true"
  )
    v-carousel.carousel(
      v-model="currentSlide"
      :cycle="carouselCycle"
      interval="5000"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      delimiter-icon=""
    )
      v-carousel-item(v-for="(item, i) in items" :key="i")
        .carousel__image
          v-img.image--blur(
            :src="item.thumb_url || item.image_1_url"
            :lazy-src="require('@/assets/images/placeholder-news.svg')"
          )
          v-img.image(
            :src="item.image_1_url || item.thumb_url"
            :lazy-src="require('@/assets/images/placeholder-news.svg')"
            contain
          )

          v-card.slide-info(flat :style="`opacity: ${carouselCycle ? 0 : 1 }`")
            v-card-text.slide-info__text
              div
                router-link.slide-info__text-title(
                  :to="{ name: 'new', params: { id: item.id }}"
                ) {{ item.caption }}
                p.slide-info__text-subtitle {{ item.text_short }}
                v-btn.slide-info__text-title-button(
                  :to="{ name: 'new', params: { id: item.id }}"
                  color="#f8f8f8"
                ) Подробнее
                p.slide-info__author
                  v-icon.marks-icon account_circle
                  |  {{ item.author }}
                .slide-marks__views(v-if="item.count_view > 100")
                  v-icon remove_red_eye
                  span {{ item.count_view }}
        v-card.slide-title(flat tile :style="`opacity: ${carouselCycle ? 1 : 0 }`")
          span.px-5.white--text {{ item.caption }}

</template>

<script>
import NewsService from '@/services/news';

export default {
  name: 'TheNews',
  data: () => ({
    currentSlide: 0,
    carouselCycle: true,
    items: [],
  }),
  computed: {
    currentItem() {
      return this.items[this.currentSlide];
    },
  },
  created() {
    const params = {
      'expand': 'thumb_url,image_1_url',
      'per-page': 5,
    };
    this.$rHandler(
      NewsService.getHotNews(params),
      ({ data }) => {
        this.items = data.data;
      },
    );
  },
};
</script>


<style lang="scss" scoped>
.carousel {
  border-radius: 4px;
  height: 600px;
}

.the-news {
  &__title {
    color: $primary-color;
    font-size: 28px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: $dark-color;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
  }

}

.carousel__image {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;

  .v-image {
    height: 100%;
  }

  .image--blur {
    filter: blur(7px) brightness(.5);
    transform: scale(1.3);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: $light-color;
  }
}

.slide-info {
  background: rgba(0,0,0,0.5);
  position: absolute;
  bottom: 50px;
  padding: 50px 25px 0;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;

  &__text-title-button {
    font-size: 16px;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }

  p {
    color: $light-color;
  }

  &__text {
    padding-top: 10px;
    padding-bottom: 0;
    text-align: center;
  }

  &__text-title {
    font-weight: bold;
    color: $light-color;
    line-height: 1.3;
    font-size: 36px;
    @media (max-width: 960px) {
      font-size: 30px;
    }
    @media (max-width: 600px) {
      font-size: 26px;
    }
  }

  &__text-subtitle {
    padding: 0;
    margin: 10px 0 20px;
    display: block;
    font-size: 18px;
    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.slide-title {
  background: rgba(var(--black-color),0.5);
  position: absolute;
  bottom: 50px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light-color;
  font-weight: bold;
  font-size: 18px;
  transition: 0.4s;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.slide-info__author {
  position: absolute;
  left: 30px;
  top: 20px;
  .marks-icon {
    color: $light-color;
  }
}

.slide-marks__views {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 15px;

  span {
    color: $light-color;
    margin-left: 5px;
  }
}

::v-deep .v-carousel__next,
::v-deep .v-carousel__prev {
  .v-btn:before,
  .v-ripple__container{
    display: none;
  }
  &:hover .v-btn__content {
    transform: scale(1.2);
  }
}

::v-deep .v-carousel__controls {
  .v-btn:before,
  .v-ripple__container{
    display: none;
  }

  .v-btn {
    background-color: transparent!important;
  }

  .v-btn:after {
    content: '';
    width: 30px;
    height: 2px;
    bottom: 10px;
    opacity: .5;
    background-color: #ffffff;
    position: absolute;
    transition: 0.3s;
  }

  .v-btn:hover:after {
    opacity: .8;
  }

  .v-btn--active {
    &:after {
      opacity: 1;
    }
  }
}

</style>
