<template lang="pug">
v-autocomplete(
  v-model="inputValue"
  :search-input.sync="search"
  :items="items"
  item-text="name"
  item-value="id"
  :loading="loading"
  clearable
  v-bind="$attrs"
)
</template>

<script>
import api from '../api';

export default {
  props: {
    value: { type: [Object, Number], default: null },
  },
  data() {
    return {
      model: null,
      search: null,
      items: [],
      loading:true,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    search() {
      // Items have already been requested
      if (this.loading) return;
      if (this.inputValue) return;
      this.loading = true;

      // Lazily load input items
      this.getRegions();
    },
    inputValue(val) {
      if (val) {
        this.$emit('change');
      }
    },
  },
  created() {
    this.getRegions();
  },
  methods:{
    getRegions() {
      const params = {
        'search[name]': this.search,
        'per-page': -1,
      };
      api.fetchRegions(params).then((response) => {
        this.items = response.data.data.filter((reg) => !reg.name.includes('Тестовый'));
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
