<template lang="pug">
  div.image-dialog(@click="openImage")
    slot
    v-dialog(
      v-if="imageUrl"
      v-model="dialog"
      max-width="100%"
      attach
    )
      img.image-dialog__image(
        :src="imageUrl"
        @click.stop="dialog = false"
      )

      template(v-slot:actions): div

</template>

<script>

export default {
  name: 'ImageDialog',
  data: () => ({
    dialog: false,
    imageUrl: null,
  }),
  methods: {
    openImage() {
      if (this.imageUrl) {
        this.dialog = true;
      } else if (this.$slots.default.length && this.$slots.default[0].tag.includes('v-img')) {
        this.imageUrl = this.$slots.default[0].componentOptions.propsData.src;
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .image-dialog {
    cursor: pointer;

    ::v-deep .v-dialog {
      overflow: hidden;
      height: 100%;
      box-shadow: none;
    }

    &__image {
      max-height: 100%;
      height: 100%;
      max-width: 100%;
      width: 100%;
      cursor: pointer;
      object-fit: contain;
    }
  }

</style>
