<template lang="pug">
  v-autocomplete(
    v-model="inputValue"
    :search-input.sync="search"
    :items="items"
    item-text="name"
    item-value="id"
    :loading="loading"
    clearable
    v-bind="$attrs"
  )
</template>

<script>
import api from '../api';
import { getRoleName } from '../helpers';

export default {
  props: {
    value: { type: [Object, Number], default: null },
    projectId: { type: [Number], required:true },
  },
  data() {
    return {
      model: null,
      search: null,
      items: [],
      loading: false,


      roleId: null,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    search() {
      if (this.loading) return;
      if (this.inputValue) return;
      this.loading = true;

      this.fetchItems();
    },
  },
  async created() {
    await this.fetchRoleId();
    this.fetchItems();
  },
  methods:{
    async fetchRoleId() {
      try {
        const params = { 'search[name]': getRoleName() };
        const response = await api.fetchProjectRoles(params);
        const [role] = response.data.data;
        this.roleId = role?.id;
      } catch (error) {
        //
      }
    },
    async fetchItems() {
      try {
        const params = { 'search[name]': this.search, 'search[project_role_id]':`${this.roleId},18`, 'expand':'has_child' };
        const response = await api.fetchQuestionCategories(params);
        this.items = response.data.data;
      }  finally {
        this.loading = false;
      }
    },
  },
};
</script>
