import Vue from 'vue';

// Vuetify
import Vuetify from './plugins/Vuetify';

import Toasted from 'vue-toasted';

import ImageDialog from '@/components/ImageDialog';

import App from './App.vue';
import router from './router';


// plugins
import RHandler from '@/plugins/RHandler';
import SetErrorsFromResponse from '@/plugins/SetErrorsFromResponse';
import Rules from '@/plugins/Rules';
import VueYoutube from 'vue-youtube';
import VueSocialSharing from 'vue-social-sharing';

Vue.use(VueYoutube);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

Vue.use(RHandler);
Vue.use(SetErrorsFromResponse);
Vue.use(Rules);
Vue.use(Toasted, {
  position: 'bottom-right',
});

Vue.component('ImageDialog', ImageDialog);


const app = new Vue({
  router,
  vuetify: Vuetify,
  render: (h) => h(App),
});

app.$mount('#app');
