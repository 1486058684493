<template lang="pug">
  v-autocomplete(
    v-model="inputValue"
    :search-input.sync="search"
    :items="items"
    item-text="name"
    item-value="id"
    :loading="loading"
    clearable
    v-bind="$attrs"
  )
</template>

<script>
import api from '../api';

export default {
  props: {
    value: { type: [Object, Number], default: null },
    region: { type: Number, required:false, default: null },
  },
  data() {
    return {
      model: null,
      search: null,
      items: [],
      loading: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    search() {
      // Items have already been requested
      if (this.loading) return;
      if (this.inputValue) return;
      this.loading = true;

      // Lazily load input items
      this.getMuns();
    },
    region() {
      this.getMuns();
    },
  },
  methods:{
    getMuns() {
      const params = {
        'search[name]': this.search,
        'search[region_id]':this.region,
        'per-page': -1,
      };
      api.fetchMunicipalities(params).then((response) => {
        this.items = response.data.data.sort((a, b) => a.name > b.name ? 1 : -1);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
