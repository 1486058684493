<template lang="pug">
  v-autocomplete(
    v-model="inputValue"
    label="Муниципалитет(начните ввод)"
    no-filter
    item-text="name"
    item-value="id"
    hide-details
    :search-input.sync="search"
    :items="items"
    v-bind="$attrs"
  )
</template>

<script>
import _ from 'lodash';

import DirectoryService from '@/services/directory';

export default {
  name: 'SelectMunitipality',
  props: {
    value: { type: [Number, String], default: null },
  },
  data() {
    return {
      items: [],
      search: '',
      cache: {},
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    search(val) {
      this.debouncedSearch(val);
    },
  },
  created() {
    this.debouncedSearch = _.debounce(this.loadMuns, 300);
  },
  methods: {
    loadMuns(val) {
      this.$rHandler(
        DirectoryService.publicMunicipalitiesFiltered({
          name: val || '',
        }),
        (response) => { this.items = response.data.data; },
      );
    },
  },
};
</script>
