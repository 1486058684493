<template lang="pug">
  v-container.svg-map.mb-5
    h1.text-center.section-title.mb-5(v-if="title") {{ title }}

    v-layout(row wrap)
      v-flex(lg8 md8)
        .map-regions
          simple-svg(
            :src="require('@/assets/map.svg')"
            width="100%"
            height="500px"
            custom-id="mapRegions"
            @load="mapWasLoaded = true"
          )
      v-flex(lg4 md4)
        v-container.list-regions.overflow-y-auto
          v-list
            v-list-item(
              v-for="(region, idx) in enabledRegions"
              :ref="region.href"
              :href="returnRegion ? '' : getLink(region.href)"
              :class="{ 'hovered-item': region.isHovered }"
              :key="idx"
              @click="regionClick(region)"
              @mouseover="onMouseOver(region.reg_id)"
              @mouseleave.passive="onMouseLeave(region.reg_id)"
            )
              v-list-item-content
                v-list-item-title {{ region.title }}

</template>

<script>
import { SimpleSVG } from 'vue-simple-svg';
import main from '@/services/main';

export default {
  name: 'SvgMap',
  components: { 'simple-svg': SimpleSVG },
  props: {
    returnRegion: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: 'Подключенные регионы' },
  },
  data:() => ({
    regions: [],
    mapWasLoaded: false,
  }),
  computed: {
    reduceRegions() {
      return this.regions.reduce((res, reg) => (
        { [reg.reg_id]: reg, ...res }
      ), {});
    },
    enabledRegions() {
      return this.regions
        .filter((i) => i.enabled)
        .map((i) => ({ isHovered: false, ...i }))
        .sort((a, b) => a.title > b.title ? 1 : -1);
    },
  },
  watch: {
    mapWasLoaded(val) {
      if (val && this.regions.length) this.initMap();
    },
  },
  created() {
    this.getRegions();
  },
  methods: {
    getRegions() {
      this.$rHandler(
        main.getRegionsForMap(),
        ({ data }) => {
          this.regions = data.data;
          if (this.mapWasLoaded) this.initMap();
        },
      );
    },
    getRegionByElem(region) {
      if (!region) return null;
      const regionId = region.getAttributeNS(null, 'id') || region.closest('g').getAttributeNS(null, 'id');
      return this.reduceRegions[regionId];
    },
    initMap() {
      const [listRegions] = document.getElementsByClassName('list-regions');
      const regions = document.getElementsByClassName('region');

      Array.from(regions).forEach((reg) => {
        const region = this.getRegionByElem(reg);

        if (region?.href === document.location.host) {
          reg.classList.add('current-region');
        }

        if (region?.enabled && region?.href) {
          reg.classList.add('pfdo-region');
          reg.style.cssText = 'fill:#295fa1;fill-opacity:1;stroke:#ffffff;stroke-width:0.75000012;stroke-opacity:1';

          reg.addEventListener('click', (e) => {
            const targetRegion = this.getRegionByElem(e.target);
            if (this.returnRegion) {
              this.regionClick(targetRegion);
            } else {
              window.location.href = this.getLink(targetRegion?.href);
            }
          });

          reg.addEventListener('mouseover', (e) => {
            const targetRegion = this.getRegionByElem(e?.target);
            if (targetRegion) {
              this.$set(targetRegion, 'isHovered', true);
              const [component] = this.$refs[targetRegion.href];
              listRegions.scroll({
                top: component.$el.offsetTop - listRegions.offsetTop,
                behavior: 'smooth',
              });
            }
          });

          reg.addEventListener('mouseleave', (e) => {
            const targetRegion = this.getRegionByElem(e?.target);
            if (targetRegion) this.$set(targetRegion, 'isHovered', false);
          });
        }
      });
    },
    getLink(domain) {
      return `https://${domain}`;
    },
    regionClick(region) {
      this.$emit('select-region', region);
    },
    onMouseOver(regId) {
      const elem = document.getElementById(regId);
      elem.classList.add('pfdo-region--hover');
    },
    onMouseLeave(regId) {
      const elem = document.getElementById(regId);
      elem.classList.remove('pfdo-region--hover');
    },
  },
};
</script>

<style lang="scss">
  .svg-map {
    .list-regions {
      max-height: 500px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: auto;

      .hovered-item {
        background-color: var(--primary-color);
        transition: .3s;

        div {
          color: var(--light-color);
        }
      }
    }

    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    svg#mapRegions {
      .pfdo-region:hover {
        opacity: 0.8;
        stroke-width: 2px !important;
      }

      .pfdo-region--hover {
        opacity: 0.7;
        stroke-width: 2px !important;
        fill: var(--secondary-color) !important;
      }

      .current-region {
        fill: $secondary-color !important;
      }

      @media (max-width: 679px) {
        height: auto !important;
      }
    }
  }
</style>
