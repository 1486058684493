<template lang="pug">
.home
  check-enrollments-banner
  certificate-faq
  v-container(grid-list-md).section-scg-map
    h1.text-center.section-title.mb-5 Региональные системы ПФДО
    svg-map.mb-5(title="")
  the-news
</template>

<script>
import CertificateFaq from './components/CertificateFaq';
import TheNews from './components/TheNews';
import SvgMap from '@/components/SvgMap';
import CheckEnrollmentsBanner from '../CheckEnrollments/components/CheckEnrollmentsBanner';


export default {
  name: 'Home',
  components: {
    CheckEnrollmentsBanner,
    CertificateFaq,
    SvgMap,
    TheNews,
  },
};
</script>
