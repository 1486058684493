import Vue from 'vue';
import Router from 'vue-router';
import goTo from 'vuetify/lib/services/goto';

import startRoutes from '@/modules/StartPage/router';
import informationPages from '@/modules/InformationPages/router';
import modulesRouters from '@/modules/PaidModules/router';
import checkEnrollmentsRouters from '@/modules/CheckEnrollments/router';


Vue.use(Router);


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
  routes: [
    /* Маршруты для главной страницы */
    ...startRoutes,
    ...informationPages,
    /* Маршруты для страниц платных модулей */
    ...modulesRouters,
    /* Маршруты для страниц "У меня есть зачисления?" */
    ...checkEnrollmentsRouters,
    {
      path: '/partner/documents',
      name: 'partner-documents',
      component: () => import('@/modules/ThePartner/views/TheDocuments'),
      meta: {
        title: 'АИС "ПФДО"',
        public: true,
      },
    },
    {
      path: '/banner.img',
      name: 'stop-banner',
      component: () => import('@/modules/StopBanner'),
      meta: {
        title: 'АИС "ПФДО"',
        public: true,
      },
    },
    {
      path: '/presentation',
      name: 'presentation',
      component: () => import('@/modules/ThePresentation'),
      meta: {
        title: 'АИС "ПФДО"',
        public: true,
      },
    },
    {
      path: '/presentation2',
      name: 'presentation2',
      component: () => import('@/modules/ThePresentation/index'),
      meta: {
        title: 'АИС "ПФДО"',
        public: true,
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/modules/404'),
      meta: {
        title: 'Страница не найдена',
        public: true,
      },
    },
    {
      path: '*',
      redirect: { name: '404' },
    },
  ],
});

const DEFAULT_TITLE = 'Портал "ПФДО"';

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else document.title = DEFAULT_TITLE;

  return next();
});


export default router;
