import axios from 'axios';

const HELPDESK_URL = process.env.VUE_APP_HELPDESK_URL;
const CHUNK_SIZE = 250 * 1024;


export default {
  fetchQuestionCategories(params) {
    return axios.get(`${HELPDESK_URL}question-categories`, { params });
  },

  fetchProjectRoles(params) {
    return axios.get(`${HELPDESK_URL}project-roles`, { params });
  },

  fetchRegions(params) {
    return axios.get(`${HELPDESK_URL}region`, { params });
  },

  fetchMunicipalities(params) {
    return axios.get(`${HELPDESK_URL}municipality`, { params });
  },

  createQuestion(data) {
    return axios.post(`${HELPDESK_URL}client-questions`, data);
  },
  getHints(data) {
    return axios.post(`${HELPDESK_URL}hints/analyze-text`, data);
  },

  uploadFile({
    data, start, fileSize, dispositionName,
  }) {
    return axios.post(`${HELPDESK_URL}uploads`, data, {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/json',
        'Content-Disposition': `attachment; filename=${dispositionName}`,
        'Content-Range': `bytes ${start}-${start + CHUNK_SIZE}/${fileSize}`,
      },
    });
  },
};
