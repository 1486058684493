<template lang="pug">
v-card(flat)
  v-card-text.certify__container(
    :class="{ 'more-opened': showMoreInfo }"
  )
    v-img.image(:src="require('@/assets/images/certificateFaq/index001.png')")
    .certify-info
      h2 Что такое сертификат?
      the-loading(v-if="loading")
      .certify-info__content(v-if="showMoreInfo" v-html="content")
      p(v-else) Сертификат – это Ваша возможность получить дополнительное образование
        |  за счет государства вне зависимости от того, где захочет обучаться Ваш ребенок.
        |  Государство гарантирует Вам, что заплатит за выбранные для Вашего ребенка
        |  кружки или секции, если их будет проводить "проверенная" организация.
</template>

<script>
import MainService from '@/services/main';


export default {
  data() {
    return {
      showMoreInfo: false,
      loading: false,
      content: 'Дополнительная информация не найдена',
    };
  },
  methods: {
    getContentCard() {
      this.loading = true;
      this.$rHandler(
        MainService.getInfoCertificateCard(),
        (response) => {
          this.content = response.data.data.text;
          this.loading = false;
        },
      );
      this.showMoreInfo = !this.showMoreInfo;
    },
  },
};
</script>
